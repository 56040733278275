import React, { useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingContext } from "../context/LoadingContext";
import { createAccountAPI } from "../service/UserService";
import CustomModal from "./CustomModal";
import TopNavigation from "./TopNavigation";

export default function CreateId(router) {
  const {
    history: {
      location: {
        state: {
          exchangeDetails: {
            exchangeAccessToken,
            exchangeAdminNameFromExchange,
            exchangeAdminPassword,
            exchangeAdminUrl,
            exchangeAdminUserName,
            exchangeIconUrl,
            exchangeName,
            exchangeUrl,
            isSiteActive,
          },
        },
      },
    },
  } = router;
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coins, setCoins] = useState(1000);
  const handleSubmit = async () => {
    if (!coins || !userName || !password)
      return toast("Please fill all the required fields", {
        type: "error",
      });

    if (password.length < 8) {
      toast("Password must be 8 characters long", {
        type: "error",
      });
      return;
    }
    if (!password.match(/[a-z]/g) || !password.match(/[A-Z]/g)) {
      toast("Password must contain uppercase and lowercase letters", {
        type: "error",
      });
      return;
    }
    handleRequest();
    const {  statusCode, response, message } = await createAccountAPI(
      exchangeName,
      {
        userName,
        password,
        balance: coins,
      }
    );

    if (statusCode != 200) {
      toast(message, {
        type: "error",
      });
    } else {
      toast(response?.data?.message, {
        type: "error",
      });
    }
  };
  const handleRequest = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
    }, 3000);
  };
  return (
    <div className="w-[100%]">
      <TopNavigation isBackNav={true} />
      <CustomModal isOpen={isModalOpen} onClose={setIsModalOpen}>
        <div className="w-[20vw] text-white flex flex-col items-center text-center">
          <p className="text-xl mt-5">
           Request submitted Successfully
            <br />
            You will be notified soon
          </p>
          {/* <p className="text-xl">Request Successfull</p> */}
          <button
            // className="blue_button py-2 px-4"
            className="w-[30%]   yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center"
            onClick={() => history.push("/")}
          >
            OK
          </button>
        </div>
      </CustomModal>
      <div className="w-[100%] flex-col px-2 py-5 justify-center items-center flex ">
        <img className="mt-10" src={exchangeIconUrl} />
        <h2 className="text-3xl mt-2 text-gray-200">{exchangeName}</h2>
        <p className="mt-2 text-gray-300 text-xl">{exchangeUrl}</p>
        <div className="w-[90%] self-center  flex flex-col items-center justify-center m-5 rounded-xl p-2 bg-[#1A1D20]">
          <p className="text-gray-200 text-lg">ID Details</p>
          <div className="bg-[#2A2E33] w-[100%] text-white mt-2 rounded-xl p-2">
            <div className="w-[100%] justify-between flex flex-row">
              <p>Min Refill</p>
              <p>1,000</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>Min Withdrawal</p>
              <p>1,000</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>Min Maintaining Bal</p>
              <p>0</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>Max Withdrawal</p>
              <p>Unlimited</p>
            </div>
          </div>
        </div>
        <div className="w-[100%] px-10 flex flex-col">
          <span className="text-gray-300 text-sm mb-1">Preferred username</span>
          <input
            onChange={(e) => setUserName(e.target.value)}
            className="p-2 text-white outline-none rounded-md bg-[#1A1D20]"
            placeholder="Preferred username"
          />
        </div>
        <div className="w-[100%] px-10 mt-2 flex flex-col">
          <span className="text-gray-300 text-sm mb-1">Password</span>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="p-2 text-white outline-none border-none rounded-md bg-[#1A1D20]"
            placeholder="Password"
          />
        </div>
        <div className="w-[100%] px-10 mt-5 flex flex-col">
          <span className="text-gray-300 text-sm mb-1">Deposit Coins*</span>
          <input
            onChange={(e) => setCoins(e.target.value)}
            value={coins}
            className="p-2 text-white outline-none rounded-md bg-[#1A1D20]"
            placeholder="Deposit Coins"
          />
        </div>
        <button
          onClick={handleSubmit}
          // className="blue_button rounded-full px-4 py-2 mt-8 text-gray-200"
          className="w-[30%]   yellow_button font-bold p-3 mt-5 text-black flex justify-center items-center"
        >
          Continue to Pay {coins}
        </button>
      </div>
    </div>
  );
}
