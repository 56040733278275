import React, { useContext, useEffect, useRef, useState } from "react";
import { getOtpAPI, verifyOtpAPI } from "../service/AuthService";
import CustomModal from "./CustomModal";
import { OtpIcon } from "./Icon";
import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext";
import { OTP_SEND_THRESOLD } from "../utils/constants";

export default function OtpModal({
  onClose,
  isOpen,
  phoneNumber,
  payload,
  isLogin,
  isForgotPassword,
  forgotPassword,
  register,
  isRegister,
  otpWaitingTime,
}) {
  const [otps, setOtps] = useState({
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
    otpDigit5: "",
    otpDigit6: "",
  });
  const [timer, setTimer] = useState(
    otpWaitingTime ? `00:${otpWaitingTime}` : `00:${OTP_SEND_THRESOLD}`
  );
  const [otpWaitTime, setOtpWaitTime] = useState(
    otpWaitingTime ? otpWaitingTime : OTP_SEND_THRESOLD
  );
  const Ref = useRef(null);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e, otpTime = false) => {
    if (otpTime) {
      setTimer(`00:${otpTime > 9 ? otpTime : `0${otpTime}`}`);
    } else {
      setTimer(`00:${otpWaitTime > 9 ? otpWaitTime : `0${otpWaitTime}`}`);
    }
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = (time) => {
    let deadline = new Date();
    deadline.setSeconds(
      time ? time + deadline.getSeconds() : deadline.getSeconds() + otpWaitTime
    );
    return deadline;
  };
  useEffect(() => {
    if (otpWaitingTime) {
      clearTimer(getDeadTime(otpWaitingTime), otpWaitingTime);
    } else {
      clearTimer(getDeadTime());
    }
  }, [otpWaitTime, otpWaitingTime]);
  const { isLoggedIn, setUser, user } = useContext(UserContext);
  const otpChangeHandler = (e) => {
    setOtps({ ...otps, [e.target.name]: e.target.value });
  };
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  const resendOTP = async () => {
    let otpType = isLogin
      ? "LOGIN"
      : isForgotPassword
      ? "CHANGE_PASSWORD"
      : isRegister
      ? "REGISTER"
      : null;
    const { data, statusCode, response, message } = await getOtpAPI(
      phoneNumber,
      otpType
    );
    if (statusCode == 200) {
      toast("OTP sent successfully", { type: "success" });
      setOtpWaitTime(otpWaitTime);
      setTimer(`00:${otpWaitTime > 9 ? otpWaitTime : `0${otpWaitTime}`}`);
    } else if (statusCode != 200) {
      toast(message, { type: "error" });
      setOtpWaitTime(parseInt(data?.time));
      setTimer(`00:${data?.time > 9 ? data?.time : `0${data?.time}`}`);
    }
  };
  const handleSubmit = async () => {
    const otp =
      otps.otpDigit1 +
      otps.otpDigit2 +
      otps.otpDigit3 +
      otps.otpDigit4 +
      otps.otpDigit5 +
      otps.otpDigit6;
    if (isForgotPassword) {
      await forgotPassword(otp);
      return;
    }
    if (isRegister) {
      await register(otp);
      return;
    }
    if (isLogin) {
      await isLogin(phoneNumber, "", true, otp);
    } else {
      const { data, statusCode, response } = await verifyOtpAPI({
        mobileNumber: phoneNumber,
        otp,
      });
      if (statusCode === 200) {
        onClose(false);
        toast("OTP verified successfully", { type: "success" });
        setUser({ ...payload, isLoggedIn: true });
      } else if (!data) {
        const {
          data: { error },
        } = response.data;
        toast(error, { type: "error" });
      }
    }
  };
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div
        ref={(node) => {
          node = node;
        }}
        className="component_body justify-start w-[90%]"
      >
        <div onClick={() => onClose(false)} className="absolute right-2 top-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <OtpIcon size="40% mt-10" />
        <div className=" text-[#7C7C7C] p-8 rounded-[19px] flex flex-col items-start">
          <p className="mb-3 text-white text-lg">Enter OTP</p>
          <p className="mb-3 text-gray-300 text-md">
            We have sent and OTP on {phoneNumber}
          </p>
          <form>
            <div className="flex-row w-[100%] otp_wrapper mb-5">
              <input
                tabIndex={1}
                maxLength={1}
                name="otpDigit1"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
              <input
                tabIndex={2}
                maxLength={1}
                name="otpDigit2"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
              <input
                tabIndex={3}
                maxLength={1}
                name="otpDigit3"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
              <input
                tabIndex={4}
                maxLength={1}
                name="otpDigit4"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
              <input
                tabIndex={5}
                maxLength={1}
                name="otpDigit5"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
              <input
                tabIndex={6}
                maxLength={1}
                name="otpDigit6"
                onChange={otpChangeHandler}
                onKeyUp={inputfocus}
              />
            </div>
          </form>
          {timer == "00:00" ? (
            <p onClick={resendOTP} className="text-[#10A2F8] cursor-pointer">
              Resend OTP
            </p>
          ) : (
            <p>Resend OTP in {timer}</p>
          )}
        </div>
        <button
          className="yellow_button p-3 w-[50%] text-white"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </CustomModal>
  );
}
