import { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  DiscountIcon,
  HomeIcon,
  PassBookIcon,
  ProfileIcon,
} from '../assets/svg/SvgComponents';
import { UserContext } from '../context/UserContext';
import { getProfileAPI } from '../service/UserService';

const BottomNavigationBar = () => {
  const { setUser, user } = useContext(UserContext);
  const tabs = [
    {
      route: '/home',
      label: 'Home',
      icon: <HomeIcon />,
    },
    {
      route: '/home/offers',
      label: 'Offers',
      icon: <DiscountIcon />,
    },
    {
      route: '/home/passbook',
      label: 'Passbook',
      icon: <PassBookIcon />,
    },
    {
      route: '/home/ids',
      label: 'Ids',
      icon: <ProfileIcon />,
    },
  ];
  const getProfileData = async () => {
    const { data } = await getProfileAPI();
    setUser({
      ...user,
      ...data,
    });
  };
  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <div className='nav_wrapper'>
      <div className='navbar_bottom'>
        {tabs.map(({ route, icon }, index) => (
          <Link
            key={index}
            activeClassName='navbar_menu_item_selected'
            className='navbar_menu_item'
            to={route}
          >
            {icon}
          </Link>
        ))}
      </div>
    </div>
  );
};
export default BottomNavigationBar;
