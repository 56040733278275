import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const Slider = ({ items }) => {
  return (
    <Swiper
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <img src={item.bannerUrl} alt={'banner'} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
